<template>
  <Section>
    <FadeIn delay="200">
  
      <Container v-if="props.content.type === 'box'">
        <div class="bg-white border-2 border-[#e8e5de] rounded-[20px] md:rounded-[35px] py-8 md:py-16 lg:py-24">
          <div class="text-center">
            <h2 class="text-section-title">Trusted across all sectors</h2>
            <p class="text-subtitle">Join a growing list of organisations making a difference</p>
          </div>
          <div class="relative mx-auto mt-8 sm:mt-16">
            <div 
            class="absolute top-0 left-0 h-full w-[50px] md:w-[80px] lg-[100px] z-10"
            style="background: linear-gradient(270deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);"
            ></div>

            <div 
            class="absolute top-0 right-0 h-full w-[50px] md:w-[80px] lg-[100px] z-10"
            style="background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);"
            ></div>
            
            <Vue3Marquee
            :gradient="true"
            :gradient-color="[255,255,255]"
            gradient-length="25%"
            >
              <img 
              v-for="logo in logos" 
              :src="useCdn(logo.image_id.image.id, logo.image_id.image.filename_download)+'?width=200'" 
              :width="logo.image_id.image.width" 
              :height="logo.image_id.image.height" 
              :alt="`Trusted by Elker - ${logo.image_id.caption}`"
              loading="lazy"
              class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6"
              >
            </Vue3Marquee>

          </div>
        </div>
      </Container>
  
      <div v-else>
        <Container>
          <div class="text-center">
            <div>
              Building <strong>trust and transparency</strong> with
            </div>
          </div>
          <div class="relative mx-auto mt-8 sm:mt-16">
            <div 
            class="absolute top-0 left-0 h-full w-[50px] md:w-[80px] lg-[100px] z-10"
            style="background: linear-gradient(270deg, rgba(249, 248, 246,0) 0%, rgba(249, 248, 246,1) 100%);"
            ></div>

            <div 
            class="absolute top-0 right-0 h-full w-[50px] md:w-[80px] lg-[100px] z-10"
            style="background: linear-gradient(90deg, rgba(249, 248, 246,0) 0%, rgba(249, 248, 246,1) 100%);"
            ></div>
            
            <Vue3Marquee
            :gradient="true"
            :gradient-color="[249, 248, 246]"
            gradient-length="25%"
            >
              <img 
              v-for="logo in logos" 
              :src="useCdn(logo.image_id.image.id, logo.image_id.image.filename_download)+'?width=200'" 
              :width="logo.image_id.image.width" 
              :height="logo.image_id.image.height" 
              :alt="`Trusted by Elker - ${logo.image_id.caption}`"
              loading="lazy"
              class="max-w-[85px] xs:max-w-[90px] md:max-w-[100px] sm:max-w-[110px] filter grayscale mx-4 md:mx-6"
              >
            </Vue3Marquee>

          </div>
        </Container>
      </div>
    </FadeIn>
  </Section>
</template>

<script setup>
import { Vue3Marquee } from 'vue3-marquee'
const { getItems } = useDirectusItems()

const props = defineProps({
  content: {
    type: Object,
    required: true,
  }
})

const logos = ref([])

const { data } = await useAsyncData(
  'block-logos',
  () => getItems({
    collection: 'global',
    params: {
      fields: [
        'logos.image_id.*',
        'logos.image_id.image.id',
        'logos.image_id.image.filename_download',
        'logos.image_id.image.width',
        'logos.image_id.image.height'
      ]
    },
  })
)

logos.value = data.value.logos
</script>